.game-over {
    font-family: '8-bit-font';
  }
  
  @font-face {
    font-family: '8-bit-font';
    /* src: url(https://fonts.gstatic.com/s/pressstart2p/v14/e3t4euO8T-267oIAQAu6jDQyK3nYivN04w.woff2) format('woff2'); */
    src: url('./assets/PressStart2P.ttf') format('truetype');
    font-display:swap;
  }

  body {
    font-family: '8-bit-font', cursive;
    text-align: center;
    margin: 0;
  }
  
  @media screen and (min-width: 800px) {
    body {
      background-size: cover;
    }
  }
  
  .container {
    width: 80%;
    margin: 0 auto;
  }

  h1 {
    font-size: 3em;
    margin-top: 50px;
  }
  
  a {
    text-decoration: none;
    color: #000;
    margin: 20px;
    font-size: 1.8em;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin: 10px 0;
  }
  
  nav a {
    display: block;
    margin-bottom: 5vh;
  }

  button {
    font-family: inherit;
    padding: 8px 16px;
  }

  input {
    font-family: inherit;
  }